<template>
  <div
    v-if="hasPageAccess(pageName)"
    id="internal-reseller-signup"
  >
    <h2 aria-label="Internal Reseller Signup Up">
      Internal Reseller Signup
    </h2>

    <b-form
      class="form-container"
      @submit.prevent
    >
      <FormProgressBar :progress="progress()" />
      <!-- ERRORS -->
      <FormErrors />
      <div class="form">
        <!-- ===== SALES ATTRIBUTION ===== -->
        <FormSection title="Sales Attribution">
          <FormGroup
            for="salesAttribution"
            label="Initials of first and last name"
            class="w-100"
          >
            <FormInput
              v-validate="{ required: true }"
              :state="validateState('Sales Attribution')"
              name="Sales Attribution"
              bind-id="salesAttribution"
              get="salesAttribution"
              set="UPDATE_SALES_ATTRIBUTION"
              invalid-feedback="Sales Attribution is a required field."
              invalid-feedback-id="sales-attribution-invalid-feedback"
            />
          </FormGroup>
        </FormSection>
        <!-- ===== BASIC INFO ===== -->
        <FormSection title="Basic Information">
          <!-- FIRST & LAST NAME -->
          <div class="d-sm-flex justify-content-sm-between">
            <FormGroup
              for="firstName"
              label="First Name"
              class="w-50-100"
            >
              <FormInput
                v-validate="{ required: true }"
                :state="validateState('First Name')"
                name="First Name"
                bind-id="firstName"
                get="firstName"
                set="UPDATE_FIRST_NAME"
                invalid-feedback="First Name is a required field."
                invalid-feedback-id="first-name-invalid-feedback"
              />
            </FormGroup>
            <FormGroup
              for="lastName"
              label="Last Name"
              class="w-50-100"
            >
              <FormInput
                v-validate="{ required: true }"
                :state="validateState('Last Name')"
                name="Last Name"
                bind-id="lastName"
                get="lastName"
                set="UPDATE_LAST_NAME"
                invalid-feedback="Last Name is a required field."
                invalid-feedback-id="last-name-invalid-feedback"
              />
            </FormGroup>
          </div>
          <!-- COMPANY NAME -->
          <FormGroup
            for="accountName"
            label="Account Name"
            class="w-100"
          >
            <FormInput
              v-validate="{ required: true }"
              :state="validateState('Account Name')"
              name="Account Name"
              bind-id="accountName"
              get="accountName"
              set="UPDATE_ACCOUNT_NAME"
              invalid-feedback="Account Name is a required field."
              invalid-feedback-id="account-name-invalid-feedback"
            />
          </FormGroup>
          <!-- EMAIL -->
          <FormGroup
            for="email"
            label="Email Address"
            class="w-100"
          >
            <FormInput
              v-validate="{ required: true, email: true }"
              :state="validateState('Email')"
              name="Email"
              bind-id="email"
              get="email"
              set="UPDATE_EMAIL"
              invalid-feedback="Please enter a valid Email."
              invalid-feedback-id="email-invalid-feedback"
              placeholder="the_best_explorer@aira.io"
              @keyup.enter.native="submit"
            />
          </FormGroup>
        </FormSection>
      </div>
      <b-button
        :disabled="submitButtonDisabled"
        class="signup-buttons w-75"
        size="lg"
        variant="primary"
        @click="submit()"
      >
        Submit
      </b-button>
    </b-form>
  </div>
  <div v-else>
    <Error403 />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as role from '../../utils/permissions';

import ValidationMixin from '../../mixin/ValidationMixin';

import Error403 from '../Error/403.vue';

import FormErrors from '../Form/FormErrors.vue';
import FormProgressBar from '../Form/FormProgressBar.vue';
import FormSection from '../Form/FormSection.vue';
import FormGroup from '../Form/FormGroup.vue';
import FormInput from '../Form/FormInput.vue';

export default {
  name: 'InternalResellerSignup',
  components: {
    Error403,
    FormErrors,
    FormProgressBar,
    FormSection,
    FormGroup,
    FormInput,
  },
  mixins: [
    ValidationMixin,
  ],
  data() {
    return {
      pageName: 'internalResellerSignup',
      submitButtonDisabled: false,
    };
  },
  computed: {
    ...mapGetters([
      'errors',
      'salesAttribution',
      'firstName',
      'lastName',
      'accountName',
      'email',
    ]),
  },
  methods: {
    hasPageAccess: role.hasPageAccess,
    hasComponentAccess: role.hasComponentAccess,
    progress() { return { full: true }; },
    async submit() {
      this.submitButtonDisabled = true;
      if (this.errors.length) this.$store.commit('CLEAR_ERRORS');

      try {
        const validation = await this.$validator.validateAll();

        if (!validation) {
          const fieldErrors = await this.veeErrors.items.map((item) => item.msg);
          this.$store.commit('PUSH_ERRORS', fieldErrors);
          this.submitButtonDisabled = false;
          return;
        }

        this.$session.set('sales-attribution', this.salesAttribution);
        this.$store.dispatch('postResellerAccount').then((response) => {
          if (response) {
            // eslint-disable-next-line
            if (confirm('Great! The Reseller Account has been created! They can login and access their Aira Reseller Portal at my.aira.io/reseller.')) {
              this.$store.commit('SET_NEED_REFRESH');
              this.$router.push('/internal');
            } else {
              this.$store.commit('SET_NEED_REFRESH');
              this.$router.push('/internal');
            }
            this.submitButtonDisabled = false;
          }
        }).catch((error) => {
          console.error(error);
          this.$store.commit('PUSH_ERRORS', [`Error: ${error.errorMessage}`]);
          this.submitButtonDisabled = false;
        });
      } catch (error) {
        console.error(error);
        this.submitButtonDisabled = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables/_colors.scss';
@import '../../styles/variables/_sizes.scss';
@import '../../styles/theme/_default.scss';

#internal-reseller-signup {
  .form {
    padding: 40px 20px 0px 20px;
  }
}

</style>
